<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md8 lg6>

        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
        </v-toolbar>

        <v-stepper v-model="uiIndex">
          <v-stepper-header>
            <v-stepper-step :editable="uiIndex > 1" :complete="uiIndex > 1" step="1">{{ $t('login.steps.1') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2">{{ $t('login.steps.2') }}</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form @submit.prevent="loginSubmit()">
                <v-text-field v-show="loginType === 'email'"
                  id="email"
                  name="email"
                  v-model="email"
                  type="email"
                  required
                  :label="$t('loginEmailInputLabel')"
                  v-validate="'required|email'"
                  :error-messages="errors.collect('email')"
                  data-vv-name="email"
                  prepend-icon="person"
                  autocomplete="username"
                />

                <v-layout v-show="loginType === 'phone'">
                  <v-flex xs5>
                    <v-combobox
                      v-model="countryCodesSelected"
                      v-validate="'required|country'"
                      :error-messages="errors.collect('country')"
                      data-vv-name="country"
                      :items="countryCodes"
                      :label="$t('commonPhoneCodeInputLabel')"
                      prepend-icon="phone"
                    >
                      <template slot="selection" slot-scope="data">{{ data.item.dialCode }}</template>
                    </v-combobox>
                  </v-flex>

                  <v-flex xs7>
                    <v-text-field
                      name="phone"
                      :label="$t('loginPhoneInputLabel')"
                      type="tel"
                      required
                      v-model="phonePartial"
                      v-validate="'required|phone'"
                      :error-messages="errors.collect('phone')"
                      data-vv-name="phone"
                    />
                  </v-flex>
                </v-layout>

                <v-text-field
                  name="password"
                  v-model="password"
                  type="password"
                  required
                  :label="$t('loginPasswordInputLabel')"
                  v-validate="'required'"
                  :error-messages="errors.collect('password')"
                  data-vv-name="password"
                  prepend-icon="lock"
                  autocomplete="current-password"
                />

                <div id="login-buttons">
                  <v-btn id="login-submit" type="submit" color="primary" depressed :loading="loading.login">{{ $t('loginSubmitLabel') }}</v-btn>
                  <SignInWithAppleButton v-if="showSignInWithApple" @siwaLoggedIn="onSiwaLoggedIn" @siwaError="onSiwaError" />
                </div>


                <p class="text-xs-center ma-0 mt-3">
                  <a v-show="loginType === 'email'" flat color="primary" @click="loginType='phone'">{{ $t('loginPhoneLink') }}</a>
                  <a v-show="loginType === 'phone'" flat color="primary" @click="loginType='email'">{{ $t('loginEmailLink') }}</a> |
                  <a color="primary" @click="startResetPasswordFlow">{{ $t('loginResetPasswordLink') }}</a> |
                  <a @click.stop="lostPhoneDialogVisible = true">{{ $t('loginLostPhoneLink') }}</a>
                </p>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form @submit.prevent="emailCodeSubmit" v-show="loginType === 'email'">
                <p>{{ $t('loginVerifyUserVerbiage', {identifier: email}) }}</p>

                <v-text-field
                  name="code"
                  v-model="code"
                  type="number"
                  required
                  :label="$t('loginVerifyInputLabel')"
                  v-validate="'required|numeric|min:6|max:6'"
                  :error-messages="errors.collect('code')"
                  data-vv-name="code"
                  prepend-icon="verified_user"
                ></v-text-field>

                <v-layout>
                  <v-btn type="submit" color="primary" :loading="loading.verify">{{ $t('loginVerifySubmitButtonLabel') }}</v-btn>
                  <v-btn flat color="primary" @click="sendEmailCode" :loading="loading.resend">{{ $t('loginResendLabel') }}</v-btn>
                </v-layout>
              </v-form>

              <v-form @submit.prevent="phoneCodeSubmit" v-show="loginType === 'phone'">
                <p>{{ $t('loginVerifyUserVerbiage', {identifier: phone}) }}</p>
                <v-text-field
                  prepend-icon="verified_user"
                  name="code"
                  label="Verification Code"
                  type="number"
                  v-model="code"
                  v-validate="'required|numeric|min:6|max:6'"
                  :error-messages="errors.collect('verification code')"
                  data-vv-name="verification code"
                ></v-text-field>
                <v-btn type="submit" color="primary" :loading="loading.verify">{{ $t('login.buttons.verify.label') }}</v-btn>
                <v-btn flat color="primary" @click="sendPhoneCode" :loading="loading.resend">{{ $t('loginResendLabel') }}</v-btn>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-dialog
          v-model="lostPhoneDialogVisible"
          width="500"
        >
          <template v-slot:activator="{ on }">

          </template>

          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              {{ $t('lost-phone.title') }}
            </v-card-title>

            <v-card-text>
              {{ $t('loginLostPhoneLinkOverview') }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                flat
                @click="startEmailLoginFlow(); dialog = false"
              >
                {{ $t('common.buttons.login.label') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script setup>
import axios from 'axios';
import {ref, computed, getCurrentInstance, onMounted} from 'vue'

import config from '../config/index.js';
import util from '../util';
import {getShowSignInWithApple} from '../util/feature.js';
import {i18n} from '../lib/i18n.js';

import SignInWithAppleButton from '../components/SignInWithAppleButton.vue';


const {proxy: vm} = getCurrentInstance();
const emit = defineEmits(['alert']);


const email = ref('');
const emailIdentifier = computed(() => `email:${email.value}`)

const countryCodes = ref(util.getCountryCodes());
const countryCodesSelected = ref(util.getCountryCode('us'));
const phonePartial = ref('');
const phone = computed(() => `${countryCodesSelected.value.dialCode}${phonePartial.value}`)
const phoneIdentifier = computed(() => `phone:${countryCodesSelected.value.dialCode}${phonePartial.value}`)

const password = ref('');
const passwordNew = ref('');

const showSignInWithApple = computed(() => getShowSignInWithApple())

const code = ref('');
const uiIndex = ref(1);

const lostPhoneDialogVisible = ref(false);
const loginType = ref('phone');

const loading = ref({
  login: false,
  resend: false,
  verify: false,
});

const dictionary = ref({
  custom: {
    email: {
      required: vm.$i18n.t('loginEmailRequired'),
      email: vm.$i18n.t('loginEmailRequired'),
    },
    phone: {
      required: vm.$i18n.t('loginPhoneRequired'),
      phone: vm.$i18n.t('loginPhoneRequired'),
    },
    country: {
      required: vm.$i18n.t('validation.country.invalid'),
      phone: vm.$i18n.t('validation.country.invalid'),
    },
    password: {
      required: vm.$i18n.t('loginPasswordRequired'),
    },
    code: {
      required: vm.$i18n.t('login.inputs.code.error', {len: 6}),
      max: vm.$i18n.t('login.inputs.code.error', {len: 6}),
      min: vm.$i18n.t('login.inputs.code.error', {len: 6}),
    },
  },
});


vm.$validator.extend('phone', {
  getMessage: field => vm.$i18n.t('validation.phone.invalid'),
  validate: value => /^\+?\d*$/.test(value)
});
vm.$validator.extend('country', {
  getMessage: field => vm.$i18n.t('validation.country.invalid'),
  validate: value => /^\+\d*$/.test(value.dialCode),
});

onMounted(() => {
  vm.$validator.localize(i18n.locale, dictionary.value);
});

async function startResetPasswordFlow() {
  vm.$router.push('/reset');
}

function startEmailLoginFlow() {
  lostPhoneDialogVisible.value = false;
  loginType.value = 'email';
  document.querySelector('#email').focus();
}

async function loginSubmit() {
  let data;

  emit('alert', { visible: false });

  if (loginType.value === 'email') {
    const valid = (await Promise.all([
      vm.$validator.validate('email'),
      vm.$validator.validate('password')
    ])).every(valid => valid);
    if (!valid) {
      loading.value.login = false;

      return;
    }

    data = {
      identifier: emailIdentifier.value,
      password: password.value
    };
  } else {
    const valid = (await Promise.all([
      vm.$validator.validate('phone'),
      vm.$validator.validate('password'),
      vm.$validator.validate('country'),
    ])).every(valid => valid);
    if (!valid) {
      return;
    }

    data = {
      identifier: phoneIdentifier.value,
      password: password.value
    };
  }

  loading.value.login = true;

  try {
    const options = { headers: util.getRequestHeaders() };
    const result = await axios.post(`${config.apiBaseUrl}/login`, data, options);
    vm.$root.manageToken = result.data;
    uiIndex.value = 2;
  } catch (err) {
    console.log(err);

    emit('alert', {
      text: vm.$i18n.t(loginType.value === 'email' ? 'errorInvalidEmailOrPassword' : 'errorInvalidPhoneOrPassword'),
      type: 'error'
    });

    await util.sendErrorLog(vm.$options.__file, 'loginSubmitError', err.message, {
      data: {
        emailIdentifier: emailIdentifier.value,
        phoneIdentifier: phoneIdentifier.value,
      },
    });
  }

  loading.value.login = false;
}

async function sendEmailCode() {
  const data = {
    identifier: emailIdentifier.value,
  };
  const options = { headers: util.getRequestHeaders() };

  loading.value.resend = true;

  try {
    emit('alert', { visible: false });
    await axios.post(`${config.apiBaseUrl}/sendcode`, data, options);

    uiIndex.value = 2;
    emit('alert', {
      text: vm.$i18n.t('common.send-code.success'),
      type: 'success'
    });
  } catch (err) {
    console.log(err);

    emit('alert', {
      text: vm.$i18n.t('common.send-code.failure'),
      type: 'error'
    });

    await util.sendErrorLog(vm.$options.__file, 'sendEmailCodeError', err.message, {
      data: {
        emailIdentifier: emailIdentifier.value,
      },
    });
  }

  loading.value.resend = false;
}

async function sendPhoneCode() {
  const data = {
    identifier: phoneIdentifier.value
  };
  const options = { headers: util.getRequestHeaders() };

  loading.value.resend = true;

  try {
    emit('alert', { visible: false });
    await axios.post(`${config.apiBaseUrl}/sendcode`, data, options);

    uiIndex.value = 2;
    emit('alert', {
      text: vm.$i18n.t('common.send-code.success'),
      type: 'success'
    });
  } catch (err) {
    console.log(err);

    emit('alert', {
      text: vm.$i18n.t('common.send-code.failure'),
      type: 'error'
    });

    await util.sendErrorLog(vm.$options.__file, 'sendPhoneCodeError', err.message, {
      data: {
        phoneIdentifier: phoneIdentifier.value,
      },
    });
  }

  loading.value.resend = false;
}

async function validateCode(type) {
  let typeValue = vm[type];
  if (!typeValue) {
    if (type === 'phone') {
      typeValue = phone.value;
    } else {
      typeValue = email.value;
    }
  }

  const data = {
    [type]: typeValue,
    code: code.value
  };
  const options = { headers: util.getRequestHeaders() };
  const result = await axios.post(
    `${config.apiBaseUrl}/validate/${type}`,
    data,
    options
  );
  vm.$root.manageToken = result.data;
}

async function emailCodeSubmit() {
  emit('alert', { visible: false });

  const valid = await vm.$validator.validate('code');
  if (!valid) {
    // Return doing nothing because the vee-validate plugin
    // is showing the user what they need to change.
    return;
  }

  loading.value.verify = true;

  try {
    await validateCode('email');

    util.setLoggedIn.call(vm);
    vm.$router.push({ name: 'home' });
  } catch (err) {
    console.log(err);

    emit('alert', {
      text: vm.$i18n.t('common.verify-code.failure'),
      type: 'error'
    });

    await util.sendErrorLog(vm.$options.__file, 'emailCodeSubmitError', err.message);
  }

  loading.value.verify = false;
}

async function phoneCodeSubmit() {
  emit('alert', { visible: false });
  loading.value.verify = true;

  try {
    await validateCode('phone');

    util.setLoggedIn.call(vm);
    vm.$router.push({ name: 'home' });
  } catch (err) {
    console.log(err);

    emit('alert', {
      text: vm.$i18n.t('common.verify-code.failure'),
      type: 'error'
    });

    await util.sendErrorLog(vm.$options.__file, 'phoneCodeSubmitError', err.message);
  }

  loading.value.verify = false;
}

/**
 * Event listener for siwaLoggedIn event
 *
 * @param manageToken
 * @returns {Promise<void>}
 */
async function onSiwaLoggedIn(manageToken) {
  vm.$root.manageToken = manageToken;
  util.setLoggedIn.call(vm);
  await vm.$router.push({ name: 'home' });
}

/**
 * Event handler for siwaError events
 *
 * @param {string} textKey
 */
function onSiwaError(textKey) {
  emit('alert', {
    text: textKey,
    type: 'error'
  });
}

</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateX(-10px);
}

#login-buttons {
  text-align: center;
}

#login-submit {
  width: 200px;
}
</style>
