
import config from '../config';

/**
 * Checks whether to show the Sign In With Apple button based on the `showSignInWithApple` query param or the config
 *
 * @returns {boolean} true if the Sign In With Apple button should be shown, false otherwise
 */
export function getShowSignInWithApple() {
  const searchParams = new URL(window.location.href).searchParams;

  return !!(searchParams.get('showSignInWithApple') || config.feature.showSignInWithApple)
}
