<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md8 lg6>

        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('reset.title') }}</v-toolbar-title>
        </v-toolbar>

        <v-stepper v-model="uiIndex">
          <v-stepper-header>
            <v-stepper-step :editable="uiIndex > 0" :complete="uiIndex > 1" step="1">{{ $t('reset.steps.label.1') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="uiIndex > 1" :complete="uiIndex > 2" step="2">{{ $t('reset.steps.label.2') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="uiIndex > 2" :complete="uiIndex > 3" step="3">{{ $t('reset.steps.label.3') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="uiIndex > 3" :complete="uiIndex > 4" step="4">{{ $t('reset.steps.label.4') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="uiIndex > 4" step="5">{{ $t('reset.steps.label.5') }}</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form @submit.prevent="sendEmailCode">
                <p>{{ $t('reset.explanations.email.1') }}</p>
                <p>{{ $t('reset.explanations.email.2') }}</p>

                <v-text-field
                  name="email"
                  :label="$t('reset.inputs.email.label')"
                  v-model="email"
                  type="email"
                  required
                  v-validate="'required|email'"
                  :error-messages="errors.collect('email')"
                  data-vv-name="email"
                ></v-text-field>

                <v-btn type="submit" color="primary" :loading="loading.email">{{ $t('reset.buttons.send.email.label') }}</v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form @submit.prevent="validateEmailCode">
                <p>{{ $t('reset.explanations.email-code.1') }}</p>
                <p>{{ $t('reset.explanations.email-code.2') }}</p>

                <v-text-field
                  name="code"
                  :label="$t('reset.inputs.email-code.label')"
                  v-model="code"
                  type="number"
                  required
                  v-validate="'required|numeric|min:6|max:6'"
                  :error-messages="errors.collect('code')"
                  data-vv-name="code"
                ></v-text-field>

                <v-btn type="submit" color="primary" :loading="loading.verify">{{ $t('reset.buttons.verify.label') }}</v-btn>
                <v-btn flat color="primary" @click="resendEmailCode" :loading="loading.resend">{{ $t('reset.buttons.resend.label') }}</v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-form @submit.prevent="sendPhoneCode">
                <p>{{ $t('reset.explanations.phone.1') }}</p>

                <v-layout>
                  <v-flex>
                    <v-combobox
                      v-model="countryCodesSelected"
                      v-validate="'required|country'"
                      :error-messages="errors.collect('country')"
                      data-vv-name="country"
                      :items="countryCodes"
                      :label="$t('commonPhoneCodeInputLabel')"
                      prepend-icon="phone"
                    >
                      <template slot="selection" slot-scope="data">{{ data.item.dialCode }}</template>
                    </v-combobox>
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      name="phone"
                      v-model="phonePartial"
                      :label="$t('reset.inputs.phone.label')"
                      type="tel"
                      required
                      v-validate="'required|phone'"
                      :error-messages="errors.collect('phone')"
                      data-vv-name="phone"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-btn type="submit" color="primary" :loading="loading.phone">{{ $t('reset.buttons.send.phone.label') }}</v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-form @submit.prevent="validatePhoneCode">
                <p>{{ $t('reset.explanations.phone-code.1') }}</p>
                <p>{{ $t('reset.explanations.phone-code.2') }}</p>

                <v-text-field
                  name="code"
                  :label="$t('reset.inputs.phone-code.label')"
                  type="number"
                  v-model="code"
                  v-validate="'required|numeric|min:6|max:6'"
                  :error-messages="errors.collect('code')"
                  data-vv-name="code"
                ></v-text-field>

                <v-btn type="submit" color="primary" :loading="loading.verify">{{ $t('reset.buttons.verify.label') }}</v-btn>
                <v-btn flat color="primary" @click="resendPhoneCode" :loading="loading.resend">{{ $t('reset.buttons.resend.label') }}</v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-form @submit.prevent="resetPassword">
                <p>{{ $t('reset.explanations.password.1') }}</p>
                <p>{{ $t('reset.explanations.password.1a') }}</p>
                <ul class="mb-3">
                  <li>{{ $t('reset.explanations.password.1b') }}</li>
                  <li>{{ $t('reset.explanations.password.1c') }}</li>
                  <li>{{ $t('reset.explanations.password.1d') }}</li>
                  <li>{{ $t('reset.explanations.password.1e') }}</li>
                </ul>

                <v-text-field
                  name="password"
                  :label="$t('reset.inputs.password.label')"
                  id="password"
                  type="password"
                  v-model="password"
                  v-validate="'required|password'"
                  :error-messages="errors.collect('password')"
                  data-vv-name="password"
                  class="mb-3"
                ></v-text-field>

                <p class="mb-0">{{ $t('reset.explanations.password.2') }}</p>
                <v-text-field
                  name="password2"
                  :label="$t('reset.inputs.password-confirm.label')"
                  id="password2"
                  type="password"
                  v-model="password2"
                  v-validate="{required: true, is: password}"
                  :error-messages="errors.collect('password-confirmation')"
                  data-vv-name="password-confirmation"
                ></v-text-field>

                <v-btn type="submit" color="primary" :loading="loading.password">{{ $t('reset.buttons.update.label') }}</v-btn>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <p class="text-xs-center ma-0 mt-3">
          <a color="primary" @click="$router.push({name: 'login'});">{{ $t('common.buttons.login.label') }}</a>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import axios from 'axios';

import config from '../config/index.js';
import util from '../util.js';
import {i18n} from '../lib/i18n.js';

import {Password} from 'august-constants';

export default {
  name: 'reset',
  data() {
    return {
      constants: {
        Password,
      },
      email: '',
      phonePartial: '',
      password: '',
      password2: '',
      code: '',
      uiIndex: 1,
      loading: {
        email: false,
        phone: false,
        verify: false,
        resend: false,
        password: false,
      },
      dictionary: {
        custom: {
          email: {
            required: this.$i18n.t('reset.inputs.email.error'),
            email: this.$i18n.t('reset.inputs.email.error'),
          },
          code: {
            required: this.$i18n.t('reset.inputs.code.error', {len: 6}),
            max: this.$i18n.t('reset.inputs.code.error', {len: 6}),
            min: this.$i18n.t('reset.inputs.code.error', {len: 6}),
          },
          phone: {
            required: this.$i18n.t('reset.inputs.phone.error'),
            phone: this.$i18n.t('reset.inputs.phone.error'),
          },
          country: {
            required: this.$i18n.t('loginPhoneCodeRequired'),
            phone: this.$i18n.t('loginPhoneCodeRequired'),
          },
          password: {
            required: this.$i18n.t('resetPasswordRequired'),
          },
          'password-confirmation': {
            required: this.$i18n.t('reset.inputs.password-confirm-1.error'),
            is: this.$i18n.t('reset.inputs.password-confirm-2.error'),
          },
        },
      },
      countryCodes: util.getCountryCodes(),
      countryCodesSelected: util.getCountryCode('us'),
    };
  },
  computed: {
    emailIdentifier() {
      return `email:${this.email}`;
    },
    phoneIdentifier() {
      return `phone:${this.countryCodesSelected.dialCode}${this.phonePartial}`;
    },
    phone() {
      return `${this.countryCodesSelected.dialCode}${this.phonePartial}`;
    }
  },
  created: function () {
    this.$validator.extend('password', {
      getMessage: field => this.$i18n.t('validation.password.invalid'),
      validate: value => this.constants.Password.isUserPasswordComplexEnough(value),
    });

    this.$validator.extend('phone', {
      getMessage: field => this.$i18n.t('validation.phone.invalid'),
      validate: value => /^\+?\d*$/.test(value)
    });

    this.$validator.extend('country', {
      getMessage: field => this.$i18n.t('validation.country.invalid'),
      validate: value => /^\+\d*$/.test(value.dialCode),
    });
  },
  mounted: function () {
    this.$validator.localize(i18n.locale, this.dictionary);
  },
  methods: {
    sendEmailCode: async function() {
      const valid = await this.$validator.validate('email');
      if (!valid) {
        // The validation plugin shows errors to the user.
        return;
      }

      const data = {
        identifier: this.emailIdentifier
      };
      const options = { headers: util.getRequestHeaders() };

      this.loading.email = true;

      try {
        this.$emit('alert', { visible: false });
        await axios.post(`${config.apiBaseUrl}/sendcode`, data, options);
        this.uiIndex = 2;
      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('common.send-code.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'sendEmailCodeError', err.message, {
          data: {
            emailIdentifier: this.emailIdentifier,
          },
        });
      }

      this.loading.email = false;
    },

    resendEmailCode: async function() {
      this.loading.resend = true;

      await this.sendEmailCode();

      this.loading.resend = false;
      this.$emit('alert', {
        text: this.$i18n.t('common.send-code.success'),
        type: 'success'
      });
    },

    sendPhoneCode: async function() {
      const valid = await this.$validator.validate('phone');
      if (!valid) {
        // The validation plugin shows errors to the user.
        return;
      }

      const data = {
        identifier: this.phoneIdentifier
      };
      const options = { headers: util.getRequestHeaders() };

      this.loading.phone = true;

      try {
        this.$emit('alert', { visible: false });
        await axios.post(`${config.apiBaseUrl}/sendcode`, data, options);

        this.uiIndex = 4;
      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('common.send-code.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'sendPhoneCodeError', err.message, {
          data: {
            phoneIdentifier: this.phoneIdentifier,
          },
        });
      }

      this.loading.phone = false;
    },

    resendPhoneCode: async function() {
      this.loading.resend = true;
      await this.sendPhoneCode();

      this.loading.resend = false;
      this.$emit('alert', {
        text: this.$i18n.t('common.send-code.success'),
        type: 'success'
      });
    },

    validateEmailCode: async function() {
      this.loading.verify = true;

      try {
        const valid = await this.$validator.validate('code');
        if (!valid) {
          // The validation plugin shows errors to the user.
          return;
        }

        await this.validateCode('email');

        this.code = '';
        this.$validator.reset();
        this.uiIndex = 3;
      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('common.verify-code.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'validateEmailCodeError', err.message, {
          data: {
            email: this.email,
            code: this.code,
          },
        });
      }

      this.loading.verify = false;
    },

    validatePhoneCode: async function() {
      this.loading.verify = true;

      try {
        const valid = await this.$validator.validate('code');
        if (!valid) {
          // The validation plugin shows errors to the user.
          return;
        }

        await this.validateCode('phone');

        this.code = '';
        this.$validator.reset();
        this.uiIndex = 5;
      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('common.verify-code.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'validatePhoneCodeError', err.message, {
          data: {
            phone: this.phone,
            code: this.code,
          },
        });
      }

      this.loading.verify = false;
    },

    validateCode: async function(type) {
      const data = {
        [type]: this[type],
        code: this.code
      };
      const options = { headers: util.getRequestHeaders() };
      const result = await axios.post(
        `${config.apiBaseUrl}/validate/${type}`,
        data,
        options
      );
      this.$root.manageToken = result.data;
    },

    resetPassword: async function() {
      const valid = (await Promise.all([
        this.$validator.validate('password'),
        this.$validator.validate('password-confirmation')
      ])).every(valid => valid);
      if (!valid) {
        // The validation plugin shows errors to the user.
        return;
      }

      this.loading.password = true;

      try {
        const data = {
          password: this.password
        };
        const options = { headers: util.getRequestHeaders() };
        const result = await axios.put(
          `${config.apiBaseUrl}/user`,
          data,
          options
        );
        this.$root.manageToken = result.data;

        util.setLoggedIn.call(this);
        this.$emit('alert', {
          text: this.$i18n.t('reset.update.password.success'),
          type: 'success'
        });
        this.$router.push({ name: 'home' });
      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('reset.update.password.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'resetPasswordError', err.message);
      }

      this.loading.password = false;
    }
  }
};
</script>
