'use strict';

import _ from 'lodash';
import axios from 'axios';
import countryTelData from 'country-telephone-data';

import config from './config';
import router from './router';
import store from './store';

// NOTE this is not really internal since all of it exported as default,
//  need to rename/refactor
const internals = {};

internals.logout = async function () {
  try {
    const data = {};
    const options = {headers: internals.getRequestHeaders()};
    await axios.post(`${config.apiBaseUrl}/logout`, data, options);

    internals.setLoggedOut();
    store.manageToken = null;

    router.push({ name: 'login' });
  } catch (err) {
    console.log(err);

    await internals.sendErrorLog('util.js', 'logoutError', err.message);
  }
};

internals.setLoggedIn = function () {
  store.loggedIn = Date.now();
  this.$emit('login');
}

internals.setLoggedOut = () => {
  store.loggedIn = null;
};

/**
 * Get the headers to use when making a request to the account api.
 * @returns {Object} The headers
 */
internals.getRequestHeaders = () => {
  const headers = {};
  const token = store.manageToken;

  if (token) {
    headers[config.headers.accountManagementToken] = token;
  }

  if (store.brandID) {
    headers[config.headers.branding] = store.brandID;
  }

  return headers;
};

/**
 * Get the user's info from account api.
 * @async
 * @returns {Promise<Object>} The user info.
 */
internals.getUser = async () => {
  try {
    if (!store.manageToken) {
      console.error('no manage token, skipping getUser');

      return;
    }

    const result = await axios.get(`${config.apiBaseUrl}/user`, {
      headers: internals.getRequestHeaders()
    });

    return result.data;
  } catch (err) {
    console.log(err);

    await internals.sendErrorLog('util.js', 'getUserError', err.message);

    internals.handleHttpError(err);
  }
};

/**
 * Get the account api info.
 *
 * @async
 * @returns {Promise<Object>} The api info.
 */
internals.getApiInfo = async () => {
  try {
    const options = { headers: internals.getRequestHeaders() };
    const result = await axios.get(`${config.apiBaseUrl}/version`, options);
    return _.get(result, 'data');
  } catch (err) {
    console.log(err);

    await internals.sendErrorLog('util.js', 'getApiInfoError', err.message);

    internals.handleHttpError(err);
  }
};

/**
 * We may want to take extra action when we get certain HTTP errors.
 *
 * @param {Object} err -
 * @returns {undefined}
 */
internals.handleHttpError = err => {
  if (err.response) {
    if (err.response.status === 401) {
      internals.logout();
    }
  }
};

/**
 * Get a list of country phone codes.
 *
 * @returns {Array} The country phone codes.
 */
internals.getCountryCodes = () => {
  return countryTelData.allCountries.map(item => {
    return {
      text: `+${item.dialCode} ${item.name}`,
      dialCode: `+${item.dialCode}`,
      value: item.iso2,
    };
  });
};

/**
 * Get the phone code data for a country.
 *
 * @param {string} countryIso - The country's ISO code.
 * @returns {Object} The phone code data.
 */
internals.getCountryCode = countryIso => {
  const index = countryTelData.iso2Lookup[countryIso];
  if (!index) {
    return;
  }

  const country = countryTelData.allCountries[index];
  return {
    text: `+${country.dialCode} ${country.name}`,
    dialCode: `+${country.dialCode}`,
    value: country.iso2
  }
};

/**
 * Send a log to the backend.
 *
 * @param {string} type - Type of log (info, error, warn, etc.)
 * @param {string} component - Which component or file the error was thrown in.
 * @param {string} event - Event name
 * @param {Object} [options]
 * @param {string} [options.error] - The error message.internals
 * @param {Object} [options.data] - Extra data to log.
 */
internals.sendLog = async (type, component, event, options = {}) => {
  const data = {
    type,
    component,
    event,
  };
  if (options.error) {
    data.error = options.error;
  }
  if (options.data) {
    data.data = options.data;
  }

  const httpOptions = {
    headers: internals.getRequestHeaders()
  };

  try {
    await axios.post(`${config.apiBaseUrl}/log`, data, httpOptions);
  } catch (error) {
    console.log(error);
  }
};

/**
 * Send an 'error' log to the backend.
 *
 * @param {string} component - Which component or file the error was thrown in.
 * @param {string} event - Event name
 * @param {string} errorMessage -
 * @param {Object} [options]
 * @param {Object} [options.data] - Extra data to log.
 */
internals.sendErrorLog = async (component, event, errorMessage, options = {}) => {
  options.error = errorMessage;

  await internals.sendLog('error', component, event, options);
}

/**
 * Send an 'info' log to the backend.
 *
 * @param {string} component - Which component or file the error was thrown in.
 * @param {string} event - Event name
 * @param {Object} [options]
 * @param {Object} [options.data] - Extra data to log.
 */
internals.sendInfoLog = async (component, event, options = {}) => {
  await internals.sendLog('info', component, event, options);
}

/**
 * Checks whether a given value is an object
 *
 * @param {*} value -
 * @return {boolean} true if value is an object, false otherwise
 */
export function isObject (value) {
  return value !== null && typeof value === 'object';
}

export default internals;
